import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DefaultContainer from "../components/DefaultContainer";
import { useNavigate, useLocation } from "react-router-dom";
import { insertOTP, validateOTP } from "../services/Auth";
import DefaultHeader from "../components/DefaultHeader";
import DefaultTextField from "../components/DefaultTextField";
import ls from "local-storage";
import Toast from "../components/Snackbar";
//verify sementara
import { connect } from "react-redux";
import { userLogin } from "../config/redux/actions/userActions";
import PropTypes from "prop-types";
import { OTPValidation } from "../services/Auth";


// export default function SendOTPScreen() {
function SendOTPScreen({ userLogin }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const [errorText, setErrorText] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");

  useEffect(() => { 
    console.log(location.state)
    //cek dulu redux user / token
    if (ls.get("sfToken") === null) {
      ls.set(
        "sfToken",
        "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
      );
    }
  }, [location.state]);

  function validatePhone(phone) {
    setPhone(phone);
    if (!phone.startsWith("08")) {
      setPhoneError(true);
      setErrorText("Dimulai dari 08");
    } else if (phone.length < 10) {
      setPhoneError(true);
      setErrorText("Minimal 11 karakter");
    } else if (phone.length > 15) {
      setPhoneError(true);
      setErrorText("Maksimal 15 karakter");
    } else {
      setErrorText("");
      setPhoneError(false);
    }
  }
  async function sendOTP() {

    // verify yang asli
    const getAvailableValidation = await OTPValidation()

    const availableValidation = getAvailableValidation.details[0].value
    
    if(availableValidation === '1'){
        await insertOTP({ phone: phone }).then((response) => {
    
          // ini untuk bypass kalau kirim otp wa error
          // setIsOpen(true)
          // setMsg("OTP berhasil terikirm")
          // setStatus(1)
    
          // setTimeout(() => {
          //   navigate("/verify-otp", {
          //     replace: true,
          //     state: {
          //       phone: "085156140217",
          //       to: location.state.to,
          //     },
          //   });
          // }, 1000)
    
          // ini yang asli kalo otp wa jalan
          if (response.success === 1 && response.waStatus === true) {
            setIsOpen(true)
            setMsg(response.msg)
            setStatus(response.success) 
            // ls.set("sfToken",response.token)
    
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString)
            const partnerID = urlParams.get('partnerID')
    
            setTimeout(() => {
              // navigate(`${location.state.to}`)
              navigate(`/verify-otp?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}`, {
                replace: true,
                state: {
                  phone: phone,
                  // to: `${location.state.to}${(location.state.to === "/order" || location.state.to === "/cart") ? "?" : "&"}partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}`,
                  to: `${location.state.to}`,
                },
              });
            }, 1000)
          } else {
            setIsOpen(true)
            setMsg(response.msg)
            setStatus(response.success)
            setTimeout(() => {
              setIsOpen(false)
            }, 100)
          }
    
        });
    } else {
      //verify sementara
      var data = {
        phone: phone,
        otp: "N/A"
      };

      await validateOTP(JSON.stringify(data)).then((response) => {
        // kasih roast untuk response ini
        if (response.success === 1) {
          setIsOpen(true);
          setMsg(response.msg);
          setStatus(response.success);

          const userDetail = response.userDetail;
          const userToken = response.token;

          if (Object.keys(userDetail).length === 0) {
            setTimeout(() => {
              navigate("/complete-profile", {
                replace: true,
                state: {
                  //phone: location.state.phone,
                  phone: phone,
                  to: location.state.to,
                  data: data
                },
              });
            }, 1000);
          } else {
            userLogin(userDetail);
            setTimeout(() => {
              navigate(location.state.to, { replace: true, state:location.state });
            }, 1000);
            // set token disini
            if (userToken) {
              ls.set("sfToken", userToken);
            }
          }
        } else {
          setIsOpen(true);
          setMsg(response.msg);
          setStatus(response.success);
          setTimeout(() => {
            setIsOpen(false);
          }, 100);
        }
      });
    }  
  }

  return (
    <DefaultContainer updateDimension={true} >
      <Toast isOpen={isOpen} message={msg} status={status} />
      <Box>
        <Grid style={{ margin: 15 }}>
          <DefaultHeader title="Login" url={location.state.to}/>
          <Grid style={{ marginTop: 15 }}>
            <DefaultTextField
              error={phoneError}
              autoComplete={"phone"}
              label="Silakan masukan nomor WhatsApp anda"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              autoFocus
              helperText={errorText}
              value={phone}
              onChange={(e) => {
                validatePhone(e.target.value);
              }}
              type={"tel"}
            />
            <Button
              variant="contained"
              fullWidth
              disabled={phone.length > 1 ? phoneError : true}
              style={{
                color: "white",
                borderRadius: 12,
                marginTop: 15,
                padding: 10,
              }}
              onClick={(e) => {
                sendOTP();
              }}
            >
              {/* Kirim OTP ke Whatsapp */}
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DefaultContainer>
  );
}

//redux move to here
const mapStateToProps = (state) => {
  return {
    userData: state.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (userDetail) => {
      dispatch(userLogin(userDetail));
    },
  };
};

SendOTPScreen.propTypes = {
  userLogin: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendOTPScreen);