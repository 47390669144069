import React from 'react'
import { Box } from '@mui/system'
import { LocationOn } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useEffect,useState } from 'react'
import {Grid, Button} from '@mui/material'

export default function LocationAccess(props) {


    useEffect(() => {
        
        // navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => 
        // {
        //     if(permissionStatus.state === "denied"){
        //         props.setIsDenied(true)
        //     }
        // })

    }, []);


    const handleRefresh = () => {
        window.location.reload();
    }

    return (
        <Box
            style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: "wrap",
            
            }}
        >
            <div style={{marginTop: "9rem",marginBottom: "9rem"}}>
                <LocationOn
                    style={{
                        fontSize: 100,
                        color: "white",
                        textAlign: "center",
                        width: "100%"
                    }}
                />
                <div style={{paddingLeft: "4rem",
                    paddingRight: "4rem",marginTop: "2.25rem"}}>  
                    <Typography
                        style={{
                            fontSize: 20,
                            fontWeight: 400,
                            marginTop: 10,
                            textAlign: "center",
                            color: "white",
                        }}
                    >
                    Mohon izinkan lokasi agar dapat menggunakan aplikasi ini.
                    </Typography>
                    <Typography
                    style={{
                        fontSize: 15,
                        fontWeight: 400,
                        marginTop: 10,
                        textAlign: "center",
                        color: "white",
                    }}
                    >
                    (Refresh halaman bila sudah mengizinkan lokasi)
                    </Typography>
                    <div
                        style={{
                            width: 200,
                            marginTop: 20,
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        >
                        <Button
                            fullWidth
                            variant="outlined"
                            color="white"
                            onClick={
                                () => handleRefresh()
                            }
                        >
                            <Typography
                                style={{ padding: 5 }}
                            >
                                Refresh
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
        )
}