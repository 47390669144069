import instance from "../axios";

export const getByID = async (id) => {
  const response = await instance.get("partners/GetByID.php?id=" + id);

  return response.data;
};

export const fCallWaiter = async (data) => {
  const response = await instance.post("partners/CallWaiter.php", data);

  return response.data;
};

export const getSpecialDiscount = async (id) => {
    const response = await instance.get("partners/GetSpecialDiscount.php?id=" + id);

  return response.data;
}