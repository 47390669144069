import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InstallMobileRoundedIcon from '@mui/icons-material/InstallMobileRounded';
import { useContext } from "react";
import { DynamicContext } from "../views/Success";

const DefaultContainer = (props) => {
  const [width, setWindowWidth] = useState(0);
  const [height, setWindowHeight] = useState(0);
  const [isOpen, setIsOpen] = useState("close");

  const { setDynamicHeight, setDynamicWidth, setDynamicRatio, isSmallPhone } = props.isContext ? props.isContextProps : { setDynamicHeight: "", setDynamicWidth: "", setDynamicRatio: "", isSmallPhone: "" }

  // sessionStorage.setItem("item_key", item_value);
  useEffect(() => {
    if (props.loading || props.updateDimension || (!props.hasOwnProperty("updateDimension") && !props.hasOwnProperty("loading")) || props.noBanner === true) {
      updateDimensions();

      if (sessionStorage.getItem("sfBanner") === "open") {
        setIsOpen("open")
      }

      if (props.noBanner || props.loading) {
        setIsOpen("close")
      }

      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }


    if (!props.loading && sessionStorage.getItem("sfBanner") === "open") {
      setIsOpen("open")
    }

    if (!props.loading && !sessionStorage.getItem("sfBanner")) {
      sessionStorage.setItem("sfBanner", "open")
      setIsOpen("open")
    }
    
    if(props.noBanner === false){
      setIsOpen("open")
    }

  }, [props.loading]);

  function updateDimensions() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    sessionStorage.setItem("width", width)
    sessionStorage.setItem("height", height)
    sessionStorage.setItem("comparison", width/height)
    setWindowWidth(width);
    setWindowHeight(height);


    if(props.isContext){
      setDynamicHeight(height);
      setDynamicWidth(width);
      setDynamicRatio(height/width);
    }

  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const onClose = (e) => {
    e.preventDefault();
    setIsOpen("close");
    sessionStorage.removeItem("sfBanner");
    sessionStorage.setItem("sfBanner", "close");
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: width, sm: 480 },
          // maxHeight: { height },
          minHeight:  isSmallPhone ? 'auto' : height ,
          backgroundColor: props.bgColor ? props.bgColor : "",
        }}
        style={{
          margin: "0px auto",
          position: "relative",
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "center",
        }}>
          {isOpen === "open" ? (
            <AppBar
              style={{
                position: "fixed",
                left: "auto",
                right: "auto",
                // margin: "0px auto",
                // alignContent: "center",
                maxWidth: 480,
              }}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="white"
                  aria-label="menu"
                  sx={{ mr: width > 420 ? 2 : 1 }}
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  style={{
                    color: "white",
                    fontSize: 16
                  }}
                >
                  Lebih Praktis di Aplikasi
                </Typography>
                <Button
                  style={{
                    color: "white",
                  }}
                  onClick={() => {
                    window.open("http://onelink.to/yc97pg", "_blank");
                  }}
                >
                  BUKA APP
                </Button>
              </Toolbar>
            </AppBar>
          )
            : (
              <div></div>
            )}
        </div>
        <div
          style={{
            marginTop: isOpen === "open" ? (width > 420 ? 64 : 56) : 0,
          }}
        >
          {props.children}
        </div>
      </Box>
    </div>
  );
};

export default DefaultContainer;
