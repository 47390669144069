import instance from "../axios";
import ls from "local-storage";

export const getByID = async (id) => {
  const response = await instance.get("menus/GetByPartnerID.php?id=" + id);

  return response.data;
};
export const getVariantByID = async (id) => {
  const response = await instance.get("variants/GetByMenuID.php?idMenu=" + id + "&partnerID=" + ls.get('sfPartner').id);

  return response.data;
};
export const search = async (keyword, partnerID) => {
  const response = await instance.get(
    "menus/Search.php?find=" + keyword + "&id=" + partnerID
  );

  return response.data;
};
export const getLatest = async (id) => {
  const response = await instance.get("menus/GetLatestByPartnerID.php?id=" + id);

  return response.data;
};
