import { Grid, Skeleton } from "@mui/material";
import React from "react";
export default function SkeletonLoader({ type }) {
  return type === "productList" ? (
    <Grid container>
      <Grid item xs={3}>
        <Skeleton
          variant="rectangular"
          width={75}
          height={75}
          style={{ borderRadius: 10 }}
        />
      </Grid>
      <Grid item xs={6}>
        <Skeleton
          variant="rectangular"
          width={125}
          height={20}
          style={{ borderRadius: 10 }}
        />
      </Grid>
      <Grid item xs={3} textAlign="right">
        <Skeleton
          variant="rectangular"
          width={75}
          style={{ borderRadius: 10 }}
        />
      </Grid>
    </Grid>
  ) : type === "productDetail" ? (
    <div></div>
  ) : type === "productImageList" ? (
    <Skeleton
      variant="rectangular"
      width={75}
      height={75}
      style={{ borderRadius: 10 }}
    />
  ) : type === "reviewPartnerImg" ? (
    <Skeleton
      variant="circular"
      width={100}
      height={100}
      style={{ borderRadius: '50%' }}
    />
  ) : type === "productImage" ? (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={300}
      style={{ borderRadius: 10 }}
    />
  ) : (
    <div></div>
  );
}
