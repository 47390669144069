import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import DefaultTextField from "../components/DefaultTextField";
import ls from "local-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { register } from "../services/Auth"
import DefaultButton from "../components/DefaultButton";
import DefaultContainer from "../components/DefaultContainer";
import DefaultHeader from "../components/DefaultHeader";
import qs from "query-string";

export const MembershipScreen = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [wantMember, setWantMember] = useState(false)
    const [birthDate, setBirthDate] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    const [formErrors, setFormErrors] = useState({})
    const [status, setStatus] = useState(0);
    const [msg, setMsg] = useState("");

    useEffect(() => {
    }, [])

    const clearDataRegist = () => {
            setEmail('')
        setBirthDate('')
        setGender('')
    }
    

    async function registerUser(dataRegist) {
        const response = await register(dataRegist);
    
        if (response.success === 1) {
            // setIsOpen(true)
            setMsg(response.msg)
            setStatus(response.success)
            // ls.set("sfToken", response.token)
    
            clearDataRegist()
    
            setTimeout(() => {
                navigate(location.state.to)
            }, 1000)
        } else {
            setMsg(response.msg)
            setStatus(response.success)
        }
    
    }

    const formValidation = async () => {
        let values = {}
    
        if (wantMember) {
            values = {
                name: ls.get("redux_localstorage_simple")?.userData[0].name,
                phone: location.state ? location.state.phone : "",
                email: email,
                birthDate: birthDate,
                gender: gender,
                masterID: ls.get("sfPartner").id_master,
                firstPartner: ls.get("sfPartner").id,
                registerMembership: true
            }
        }

    
        const errors = {}
        const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    
        if (wantMember) {
            //   if (!values.name) {
            //     errors.name = 'Name tidak boleh kosong'
            //   }
    
            if (!values.email) {
                errors.email = 'Email tidak boleh kosong'
            } else if (!regEx.test(values.email)) {
                errors.email = 'email tidak valid'
            }
    
            if (!values.birthDate) {
                errors.birthDate = 'Tanggal lahir tidak boleh kosong'
            }
    
            if (!values.gender) {
                errors.gender = 'Gender tidak boleh kosong'
            }
        } 
        // else {
        //   if (!values.name) {
        //     errors.name = 'Name tidak boleh kosong'
        //   }
        // }
    
        setFormErrors(errors)
    
        if (Object.keys(errors).length === 0) {
            console.log(values)
            registerUser(values)
        }
    }

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) ?? "";

    return (
        <DefaultContainer >
            <Box
                style={{
                    paddingRight: 15,
                    paddingLeft: 15,
                    textAlign: "left",
                    position: "relative",
                    backgroundColor:"white"
                }}
            >
          <Grid
            container
            style={{
              position: "sticky",
              top: 0,
              justifyContent: "center",
              maxWidth: 480,
              background: "#fff",
              padding: "15px 0px",
            }}
          >
            <DefaultHeader title="Daftar Member" url={location.state.to} />
          </Grid>
            <div>
            <Grid container style={{ marginTop: 15 }}>
                <Grid item xs={1}>
                    <Checkbox
                        style={{ margin: 0, padding: 0 }}
                        value={wantMember}
                        onClick={(e) => {
                            setWantMember(!wantMember);
                        }}
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography>
                    Daftarkan saya jadi member di {`${ls.get("sfPartner").name}`}
                    </Typography>
                </Grid>
            </Grid>
            {wantMember ? (
            <Grid container item xs={12} style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                <Typography>Tanggal Lahir</Typography>
                </Grid>
                <Grid item xs={12}>
                <DefaultTextField
                    type={"date"}
                    fullWidth
                    required
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    error={formErrors.birthDate ? true : false}
                    helperText={formErrors.birthDate}
                />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 15 }}>
                <DefaultTextField
                    type={"email"}
                    fullWidth
                    label={"Email"}
                    required
                    value={email}
                    placeholder={"Masukkan email"}
                    onChange={(e) => {
                    setEmail(e.target.value)
                    }}
                    error={formErrors.email ? true : false}
                    helperText={formErrors.email}
                />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                    Gender
                    </FormLabel>
                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Wanita"
                    value={gender}
                    name="radio-buttons-group"
                    onChange={(e) => {
                        setGender(e.target.value)
                    }}
                    >
                    <FormControlLabel
                        value="Wanita"
                        control={<Radio />}
                        label="Perempuan"
                    />
                    <FormControlLabel
                        value="Pria"
                        control={<Radio />}
                        label="Laki-laki"
                    />
                    </RadioGroup>
                    <p style={{ color: '#f44336', fontSize: 13, margin: 0, paddingLeft: 15 }}>{formErrors.gender && formErrors.gender}</p>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <DefaultButton
                        title="daftar"
                        onClick={() => formValidation()}
                    />
                </Grid>
            </Grid>
            ) : (
            <div></div>
            )}
            </div>
            </Box>
        </DefaultContainer>
    )
}