import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import MoneyFormat from "../components/MoneyFormat";
import { useNavigate } from "react-router-dom";
import ls from "local-storage";


const LatestMenuCard = ({ menu, setMsg, setIsToastOpen, statusValidation, handler }) => {

    const navigate = useNavigate();
    const [width, setWindowWidth] = useState(0);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    useEffect(() => {
        updateDimensions();

        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [])

    return (
        <Card
            sx={{
                width: width >= 480 ? '220px' : width * 48 / 100
            }}
            onClick={() =>{

                if(statusValidation){
                    if(!ls.get("sfButtonBehavior").disabled){
                        navigate("/product-details", {
                            state: { details: menu }})                
                    } else {
                        setIsToastOpen(true)
                        setMsg("Anda belum berada dekat lokasi")
                    }
                }else{
                    handler()
                }

            }
            }
        >
            <CardActionArea>
                <div
                    style={{
                        height: 220,
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    <img
                        alt=""
                        src={menu.thumbnail ? menu.thumbnail : "/assets/img/image-not-found.png"}
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover'
                        }}
                        onError={(e)=>{
                            e.target.src("/assets/img/image-not-found.png")
                        }}
                    />
                </div>
                <CardContent>
                    <Typography
                        gutterBottom
                        component="div"
                        style={{
                            fontSize: 15,
                            fontWeight: 500
                        }}
                    >
                        {menu.nama}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {   menu.harga_diskon !== "0" ?
                            <div>
                                <span style={{textDecoration: 'line-through', fontSize:"13px", color:"#A0A0A0"}}>
                                    <MoneyFormat
                                        value={parseInt(menu.harga)}
                                    />
                                </span>
                            </div>
                            :
                            <div></div>
                        }
                        <MoneyFormat
                            value={menu.harga_diskon === "0" ? parseInt(menu.harga) : parseInt(menu.harga_diskon)}
                        />
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card >
    );
}

export default LatestMenuCard