import React, { useEffect, useState } from 'react'
import DefaultContainer from "../components/DefaultContainer";
import DefaultButton from "../components/DefaultButton";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ls from "local-storage";

const OrderReceivedScreen = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(()=>{
        // if(location.state.membership === false){
        //     navigate('/membership', {state: {to: "/order", phone:ls.get("redux_localstorage_simple").userData[0].phone}})
        // } else {
        //     navigate('/order', {state: location.state})
        // }
    },[])

    function handleButtonKembali(){
        if(location.state.membership === false){
            navigate('/membership', {state: {to: "/order", phone:ls.get("redux_localstorage_simple").userData[0].phone}})
        } else {
            navigate('/order', {state: location.state})
        }
    }
    
    function handleButtonDetailPesanan(){
        if(location.state.membership === false){
            navigate('/membership', {state: {to: `/history-detail?transactionID=${location.state.id}&partnerID=${ls.get("sfPartner").id}`, phone:ls.get("redux_localstorage_simple").userData[0].phone}})
        } else {
            navigate(`/history-detail?transactionID=${location.state.id ?? ls.get("trxFromQR")}&partnerID=${ls.get("sfPartner").id}`, {state: location.state})
        }
    }

    return (
        <DefaultContainer>
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div
                    style={{
                        // border: '2px solid black',
                        width: '100%',
                        height: 300,
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center"
                    }}
                >
                    <img
                        alt=""
                        src="/assets/img/success-order.png"
                        style={{
                            width: '70%',
                            height: 'auto',
                            objectFit: 'cover'
                        }}
                    />
                </div>
                <Typography
                    style={{
                        fontSize: 20
                    }}
                >
                    <strong>Pesanan anda telah diterima</strong>
                </Typography>
                <Typography
                    style={{
                        fontSize: 16,
                        marginTop: 15,
                        padding: '0px 15px',
                        textAlign: 'center'
                    }}
                >
                    Silakan tunggu pesanan kamu, terima kasih
                </Typography>
                {
                    ls.get("sfPartner").wifi_password ?
                    <>
                        <Typography
                            style={{
                                fontSize: 16,
                                marginTop: 10,
                                padding: '0px 15px',
                                textAlign: 'center'
                            }}
                        >
                            Sambil menunggu pesanan kamu, nih ada wifi buat kamu
                        </Typography>
                        <Typography>
                            SSID: {ls.get("sfPartner").wifi_ssid}
                        </Typography>
                        <Typography>
                            Password: {ls.get("sfPartner").wifi_password}
                        </Typography>
                    </>
                    :
                    <></>
                }
                <div
                    style={{
                        width: "90%"
                    }}
                >
                    <DefaultButton
                        fullWidth
                        title="Kembali"
                        onClick={() => {
                                handleButtonKembali()
                            }
                        }
                    />
                </div>
                <div
                    style={{
                        width: "90%"
                    }}
                >
                    <DefaultButton
                        title="Lihat Pesanan"
                        onClick={() => {
                                handleButtonDetailPesanan()
                            }
                        }
                    />
                </div>
            </div>
        </DefaultContainer>
    )
}

export default OrderReceivedScreen