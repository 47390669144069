import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import MoneyFormat from "./MoneyFormat";
import { useState } from "react";

export default function CartItemCard({ productDetails, index }) {
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState(productDetails.data.thumbnail)

  return (
    <Grid
      style={{ marginBottom: 20 }}
      container
      key={index}
      onClick={(e) => {
        navigate("/item-details", {
          state: { details: productDetails },
        });
      }}
    >
      <Grid item xs={3}>
        <div
          style={{
            width: 75,
            height: 75,
            borderRadius: 12,
            marginBottom: 15,
            overflow: 'hidden'
          }}
        >
          {productDetails.data.thumbnail === null ||
            productDetails.data.thumbnail === "" ? (
            <img
              src={"/assets/img/image-not-found.png"}
              alt="product"
              width={75}
              style={{
                borderRadius: 12,
              }}
            />
          ) : (
            <img
              src={thumbnail}
              alt="product"
              style={{
                borderRadius: 12,
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
              onLoad={
                setTimeout(()=>{
                }, 250)
              }
              onError={()=>{
                setThumbnail("/assets/img/image-not-found.png")
              }}
            />
          )}{" "}
        </div>
      </Grid>

      <Grid item xs={9}>
        <Typography
          style={{
            fontWeight: 600
          }}
        >
          {productDetails.data.nama}
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              width: "40%",
            }}
          >
            {productDetails.price}
          </span>
          <span
            style={{
              width: "20%",
            }}
          >
            X {productDetails.qty}
          </span>
          <span
            style={{
              width: "40%",
              textAlign: "right",
              fontSize: 13,
              fontWeight: 600,
            }}
          >
            <MoneyFormat value={productDetails.totalPrice} />
          </span>
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            marginTop: 5,
            overflowWrap:"break-word"
          }}
        >
          {productDetails.variant.length > 0 &&
            productDetails.variant.map((item, i) => {
              return (
                <Fragment key={i}>
                  <span style={{ margin: 0 }} key={i}>
                    {item.data_variant[0].name}
                    {i + 1 === productDetails.variant.length ? "" : ", "}
                  </span>
                </Fragment>
              );
            })}
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            color: "gray",
            width: "65%",
            fontStyle: "italic",
          }}
        >
          {productDetails.notes}
        </Typography>
      </Grid>
    </Grid>
  );
}
