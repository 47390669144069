import React, { useEffect, useState } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Toast = ({ isOpen, message, status, setState }) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      handleClick();
      if(setState){
        setState(true)
      }
    }

    if(!isOpen){
      if(setState){
        handleClose()
        setState(false)
      } else {
        setTimeout(()=>{
          handleClose()
        },1500)
      }
    }
  }, [isOpen]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => {
        handleClose();
      }}
    >
      <Alert
        onClose={handleClose}
        severity={status === 1 || status === "1" ? "success" : (status === 2 || status === "2" ) ? "warning" : "error"}
        sx={{
          width: "100%",
          color: "#fff",
        }}
      >
        {message ? message : "Success"}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
