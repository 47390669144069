import instance from "../axios";

export const calculate = async (data) => {
  const response = await instance.post("transactions/Calculate.php", data);

  return response.data;
};
export const create = async (data) => {
  const response = await instance.post("transactions/Create.php", data);

  return response.data;
};
export const getHistory = async ({ partnerID }) => {
  const response = await instance.get(
    "history/GetOngoing.php?partnerID=" + partnerID
  );

  return response.data;
};
export const getReview = async ({ transactionID }) => {
  const response = await instance.get(
    "reviews/GetReviewContent.php?transactionID=" + transactionID 
  );

  return response.data;
};
export const getDetails = async ({ id }) => {
  const response = await instance.get("history/GetDetails.php?id=" + id);
  return response.data;
};
export const getStatus = async (id, phone) => {
  const response = await instance.get("transactions/GetStatus.php?id=" + id + "&phone=" + phone);
  return response.data;
};
export const getStatusForQR = async (id, phone, qr) => {
  const response = await instance.get("transactions/GetStatus.php?id=" + id + "&phone=" + phone + "&QR=" + qr);
  return response.data;
};
export const getProgramActive = async (partnerID) => {
  const response = await instance.get("programs/GetActiveProgram.php?partnerID=" + partnerID);
  return response.data;
};
export const reCreate = async (data) => {
  const response = await instance.post("transactions/ChangePaymentMethod.php", data);

  return response.data;
};
export const cancelOrder = async (data) => {
  const response = await instance.post("transactions/Cancel.php", data);

  return response.data
}