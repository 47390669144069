import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StarRateIcon from '@mui/icons-material/StarRate';

const ReviewItem = ({ data }) => {

    const [width, setWindowWidth] = useState(0);
    const [dateReview, setDateReview] = useState("");
    const [reviews, setReviews] = useState("");
    // const [starIcons, setStarIcons] = useState([]);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    useEffect(() => {
        updateDimensions();

        if (data) {
            const date = new Date(data.created_at)

            const formattedDate = date.toLocaleDateString('en-GB', {
                day: 'numeric', month: 'short', year: 'numeric'
            })

            setDateReview(formattedDate)
        }

        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [data])

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    return (
        <Card
            sx={{
                // width: width >= 480 ? '220px' : width * 48 / 100,
                width: '100%',
                marginBottom: 1
                // minHeight: 120
            }}
        >
            <CardContent
                className="content"
                style={{
                    padding: 15,
                    display: 'flex',
                    // minHeight: 120,
                    flexDirection: 'column',
                    gap: 10
                    // justifyContent: 'space-between'

                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        style={{
                            fontSize: 14
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 500
                            }}
                        >
                            {data.anonymous === "1" ? "Anonim" : data.name} {bull}

                        </span>
                        <span
                            style={{
                                color: "#6c757d"
                            }}
                        >
                            {dateReview}
                        </span>
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 500
                            }}
                        >
                            {data.rating}
                        </span>
                        <StarRateIcon
                            style={{
                                color: "#FFDF00",
                                marginLeft: -2,
                                fontSize: 20
                            }}
                        />
                    </div>
                </div>
                <Typography variant="body2">
                    {data.review}
                    {/* Test */}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default ReviewItem