import { USER_LOGIN } from "../actions/userActions";
import { USER_LOGOUT } from "../actions/userActions";

const initState = [];

const userReducer = (state = initState, action) => {
  const detail = action.payload;

  if (action.type === USER_LOGIN) {
    state = [...state, detail];
  }

  if (action.type === USER_LOGOUT) {
    state = [];
  }

  return state;
};

export default userReducer;
