import { useEffect, useState } from "react"
import { getSmartWaiterContent } from "../services/SmartWaiter"
import ls from "local-storage";
import { useLocation, useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import { Typography } from "@mui/material";

export default function SmartWaiter (props){
    const navigate = useNavigate();
    const [smartData, setSmartData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        fetchSmartWaiterData()
    }, [isLoading])
    
    async function fetchSmartWaiterData(){
        const data = await getSmartWaiterContent(ls.get("partnerID"))

        //SORT DATA DENGAN IS_RECOMMENDED DAN DENGAN ALPHABETIC SEHINGGA DATA DENGAN NAMA BERULANG DAPAT DIFILTER
        const firstSort = data.recommended.sort((a,b) => {
            if(parseInt(a.is_recommended) > parseInt(b.is_recommended) ){
                return 1
            }

            if(parseInt(a.is_recommended) < parseInt(b.is_recommended) ){
                return -1
            }

            return 0
        })

        const sortedData = firstSort.sort((a,b) =>{

            const nameA = a.nama.toLowerCase()
            const nameB = b.nama.toLowerCase()
            if(nameA < nameB){
                return -1
            }
            if(nameA < nameB){
                return 1
            }
            return 0
        })

        //DATA DENGAN NAMA YANG SAMA AKAN TERFILTER
        const filteredData = sortedData.filter((item, i) => {
            const prev = undefined ? "firstData" : {...sortedData[i-1]}
            if(prev === "firstData"){
                return item.nama
            }

            return prev.nama !== item.nama && item.stock !== "0"
        })

        //BILA DATA SUDAH DI DALAM CART, DATA TIDAK MUNCUL LAGI
        const filterByCartData = filteredData.filter((item, i) => {
            const tempFilter = props.cartData.filter((comp) => {
                return comp.data.nama === item.nama
            })

            return tempFilter.length < 1
        })


        //SORTING WITH IS RECOMMENDED
        const sortedDataFinal = filterByCartData.sort((a,b) => {
            if(parseInt(a.is_recommended) > parseInt(b.is_recommended) ){
                return -1
            }

            if(parseInt(a.is_recommended) < parseInt(b.is_recommended) ){
                return 1
            }

            return 0
        })

        setSmartData(sortedDataFinal)

        setIsLoading(false)
    }

    return (
        smartData.length > 0 ?
        <div>
            <Typography style={{color: "white", backgroundColor:"#1fb0e6", paddingLeft:"1rem", paddingTop:"0.5rem",paddingBottom:"0.5rem", borderTopLeftRadius: "0.5rem",
borderTopRightRadius: "0.5rem", marginBottom: "1rem"
}}>Mau Yang Ini Sekalian?</Typography>
            {
                smartData.map((item, i) => {
                    return (
                    <ProductCard
                        productDetails={item}
                        index={i}
                        key={i}
                        dataFrom="smartWaiter"
                    />
                    )
                })
            }
            
        </div>
        :
        <div></div>
    )
}