import cartReducer from "./cartReducer";
import userReducer from "./userReducer";

import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  cartData: cartReducer,
  userData: userReducer
});

export default rootReducer;
