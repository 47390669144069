import React from 'react'
import { Stack, Skeleton, Grid } from '@mui/material';

const SkeletonCalculate = () => {

    return (
        <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
            <Grid
                container
                style={{
                    marginTop: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
            </Grid>
            <Grid
                container
                style={{
                    marginTop: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
            </Grid>
            <Grid
                container
                style={{
                    marginTop: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
            </Grid>
            <Grid
                container
                style={{
                    marginTop: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
            </Grid>
            <Skeleton variant="rounded" width={450} height={44} />
        </Stack>
    )
}

export default SkeletonCalculate